import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';
import Box from 'common/components/Box';
import Fade from 'react-reveal/Fade';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import { ControlWrapper } from './controlSection.style';
import ControlImage from 'common/assets/image/crypto/control.svg';
import { Link } from 'gatsby';


const ControlSection = ({
  row,
  col,
  title,
  description,
  smallerDescription,
  btnStyle,
  sectionSubTitle,
  cardArea,
  readMoreTitle,
}) => {

  const [hoursLeft, setHoursLeft] = useState(0);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
 
    } else {
      // Render a countdown
      setHoursLeft(hours)
      return (
        <div className="countPortion">
          <div className="countSingle">
            {days} <span className="countText">Days</span>
          </div>
          <div className="countSingle">
            {hours} <span className="countText">Hours</span>
          </div>
          <div className="countSingle">
            {minutes} <span className="countText">Minutes</span>
          </div>
          <div className="countSingle">
            {seconds} <span className="countText">Seconds</span>
          </div>
        </div>
      );
    }
  };
  

  function getCountDate() {
    let currentDate = new Date()
    let countTimestamp = new Date().setUTCHours(8,0,0) // Set to 8am UTC time (10am Zurich time)
    let countDate = new Date(countTimestamp)
    if (countDate < currentDate) {
      countDate.setUTCDate(countDate.getUTCDate() + 1) 
    }
    return countDate

  }
  return (
    <ControlWrapper id="trigger">
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Image
              src={ControlImage}
              className="controlImage"
              alt="Control Section Image"
            />
          </Box>
          <Box className="colright" {...col} {...cardArea}>
            <Text
              content="What's a trigger event?"
              {...sectionSubTitle}
            />
            <FeatureBlock
              title={
                <Heading
                  content={ "Trigger Events" }
                  {...title}
                />
              }
              description={
                <Text style={{marginBottom: "20px"}}
                  content="Batchy doesn't bridge your crypto immediately. Instead it waits for a trigger event. A trigger event occurs when Batchy receives 20 bridge requests OR 24 hrs has passed. This means the more users Batchy gets the faster the bridge goes."
                  {...description}
                />
              }
            />

            {/* <Fade up>
              <Box style={{marginBottom: "20px"}} className="countDownSection">
                <Countdown
                  date={getCountDate()}
                  renderer={renderer}
                  completed={false}
                />
              </Box>
              <Text style={{marginBottom: "0px", marginTop: "10px"}} content={`The next trigger event is in ${hoursLeft} hours or earlier`} {...smallerDescription} />
            </Fade> */}
            <Box className="countDownButton">
              <Link to="/app">
                <Button
                  title="OPEN BATCHY"
                  className="countDownMainButton"
                  {...btnStyle}
                />
              </Link>
              {/* <Button
                title="35% Bonus"
                className="countDownDiscountButton"
                {...btnStyle}
              /> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </ControlWrapper>
  );
};

// Transactions style props
ControlSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object,
};

// Transactions default style
ControlSection.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Transactions section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
  },
  // Transactions section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  smallerDescription: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  readMoreTitle: {
    as: 'span',
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
  },
};

export default ControlSection;
